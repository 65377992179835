#tags-filter-div:focus-within #tags-dropdown-projects {
  display: block;
}

#tags-dropdown-projects {
  height: auto;
  background: white;
  padding: 0;
  padding-bottom: 5;
  position: absolute;
  width: 99.9%;
  margin-top: -5;
  border: none;
  z-index: 10;
  border-radius: 0 0 0.25rem 0.25rem;
  display: none;
}
#tags-dropdown-projects:hover {
  display: block;
}
.empty-folder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.projects-menu {
  max-height: calc(100% - 119px);
}
