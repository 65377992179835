.fab-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  border: 1px solid lightgray;
  transition: all 0.1s;
  height: 30px;
  width: 30px;
  margin-bottom: 15px;
}

.fab-button .icon-wrap .icon {
  user-select: none;
  display: flex;
}

.fab-button .icon-wrap [class*="hakobio2-"]:before {
  line-height: 25px;
}

.fab-button .icon-wrap .hakobio2-previous_icon:before {
  margin-right: 2px;
}

.fab-button-multi {
  user-select: none;
}

.fab-button-multi > .fab-button {
  margin-bottom: 0;
}
.fab-button-multi > .fab-button:last-of-type {
  margin-bottom: 15px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
