.plugin-button {
  height: 40px;
  cursor: pointer;
  transition: all 0.1s, width 0.5s;
  white-space: nowrap;
  overflow: hidden;
}

.plugin-button:only-child {
  border-radius: 0 15px 15px 0 !important;
}

.plugin-button:last-child {
  border-radius: 0 0 15px 0;
  border-bottom: 1px solid var(--dark-grey) !important;
}

.plugin-button:first-child {
  border-radius: 0 15px 0 0;
  border-top: 1px solid var(--dark-grey) !important;
}

.plugin-animation-appear,
.plugin-animation-enter {
  opacity: 0;
  transition: opacity 0.5s;
}

.plugin-animation-appear.plugin-animation-appear-active,
.plugin-animation-enter.plugin-animation-enter-active {
  opacity: 1;
}

.plugin-animation-leave {
  opacity: 1;
  transition: opacity 0.5s;
}
.plugin-animation-leave.plugin-animation-leave-active {
  opacity: 0;
}
