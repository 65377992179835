.arrow-icon-dropdown:before {
  transform: rotate(90deg);
  font-size: 12px;
  padding: 0;
}
.arrow-est:before {
  transform: rotate(180deg);
  transition: 0.5s;
}
.arrow-south:before {
  transform: rotate(270deg);
  transition: 0.5s;
}
