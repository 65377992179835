body {
  overflow: hidden;
}

body *,
* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a .link-in-button {
  color: white;
}

a .link-in-button:hover {
  color: var(--primaryColor);
}

.unity,
.unity-container {
  height: 100%;
  width: 100%;
  position: unset !important;
}

.controlButtons {
  z-index: 100;
  position: fixed;
  bottom: 35px;
  right: 28px;
}

.App {
  width: 100vw;
  line-height: 1.5;
}

.text-center {
  text-align: center;
}

.hoverable:hover {
  background-color: var(--primaryColor);
  color: white;
  cursor: pointer;
}

.popover {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.popover button {
  width: 100%;
  background-color: white;
  border: none;
  border-radius: 0;
  padding: 0.5rem 1rem;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
}

.popover button:hover {
  color: var(--primaryColor);
  background-color: var(--grey);
}

.react-flow__handle-top,
.react-flow__handle-bottom,
.react-flow__handle-left,
.react-flow__handle-right {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: none !important;
}

.react-flow {
  user-select: none;
}

.react-flow__controls {
  right: 10px;
  left: auto !important;
}

.react-flow__node {
  border: 1px dashed transparent;
}

.element-wrap:hover {
  background-color: var(--light-grey);
}

.radar {
  width: 6px;
  height: 6px;
  border-radius: 50px;
  animation: radar 1.5s infinite ease-in-out;
}

.radarCustomComponent {
  width: 6px;
  height: 6px;
  border-radius: 50px;
  animation: radarCustomComponent 1.5s infinite ease-in-out;
}

@keyframes radarCustomComponent {
  0% {
    width: 30px;
    height: 30px;
  }
  100% {
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
    transform: translate(-4px, -4px);
  }
}

.non-select {
  user-select: none;
}

.icon-parent .icon-hover {
  display: none;
}

.icon-parent:hover .icon-hover {
  display: block;
}

.shadow-button:hover {
  -webkit-box-shadow: 3px -3px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px -3px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 3px -3px 5px 0px rgba(0, 0, 0, 0.2);
  margin-top: 0.35rem;
  margin-right: 0.5rem;
}

.shadow-button {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-right: 0.25rem;
  transition: all 0.2s ease-in-out;
}

@keyframes radar {
  0% {
    width: 8px;
    height: 8px;
  }
  100% {
    width: 18px;
    height: 18px;
    max-width: 18px;
    max-height: 18px;
    transform: translate(-5px, -5px);
  }
}

.radarGhost {
  width: 6px;
  height: 6px;
  border-radius: 50px;
  animation: radarGhost 1.5s infinite ease-in-out;
}

@keyframes radarGhost {
  0% {
    width: 6px;
    height: 6px;
  }
  100% {
    width: 18px;
    height: 18px;
    max-width: 18px;
    max-height: 18px;
    transform: translate(-6px, -6px);
  }
}

.buttonGhostX:hover {
  background-color: var(--orange) !important;
  color: white !important;
}

.buttonGhostV:hover {
  background-color: white !important;
  color: var(--green) !important;
}

@media print {
  .page-break-after {
    break-after: always;
  }
}

.li-autocomplete {
  cursor: pointer;
}
.li-autocomplete:hover {
  background-color: var(--light-grey);
}

.left-panel-sm {
  width: 320px;
  flex-shrink: 0;
}

@media screen and (min-width: 1537px) {
  .left-panel-sm {
    width: 400px;
  }
}

:root {
  --tubing-blue: #113da6;
  --tubing-green: #29a677;
  --tubing-purple: #8719a6;
  --tubing-pink: #a75f22;
}

.tubing-blue .tubing {
  fill: var(--tubing-blue);
}
.tubing-green .tubing {
  fill: var(--tubing-green);
}
.tubing-purple .tubing {
  fill: var(--tubing-purple);
}
.tubing-pink .tubing {
  fill: var(--tubing-pink);
}

a {
  color: var(--primaryColor);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
